export const messages = {
  "en": {
    draw: "draw",
    turn: "player {name} turn",
    won: "player {name} won",
  },
  "pt-br": {
    draw: "empate",
    turn: "é a vez do jogador {name}",
    won: "jogado {name} ganhou",
  },
};
